import type { ApolloError } from '@apollo/client';
import useCatalogQueryOptions from '@ecomm/graphql/useCatalogQueryOptions';
import type { CommercetoolsPackageQuery } from '@ecomm/shop-configuration/models';
import { useAvailabilityStateBySlugQuery } from '@ecomm/shop/graphql/AvailabilityStateBySlugQuery.generated';
import { PARENT_CFU_CATEGORY } from '../constants';
import { mapCTCFUtoKronosCFU } from '../mappers/mapCTCFUtoKronosCFU';
import type { UseQueryResult } from '../models/main';
import { useSearchBundleByKeyQuery } from '../queries/SearchBundleByKey.generated';
import { useGetCategoryByKey } from './useGetCategoryByKey';
import { useIsCTAvailabilityActive } from './useIsCTAvailabilityActive';
import { useSearchProductByKey } from './useSearchProductByKey';

type GetCommercetoolsCFUResult = UseQueryResult<CommercetoolsPackageQuery>;

export const useGetCommercetoolsCFU = (cfuSlug: string): GetCommercetoolsCFUResult => {
  const isCTAvailabilityActive = useIsCTAvailabilityActive();

  const opts = useCatalogQueryOptions({
    throwError: false,
    variables: { package: cfuSlug },
    suspend: false,
    skip: !cfuSlug,
  });

  // Fetch Availability state from graph.k8s.onepeloton.com/graphql
  const {
    data: availabilityState,
    error: availabilityStateError,
    loading: availabilityStateLoading,
  } = useAvailabilityStateBySlugQuery({
    ...opts,
    useApq: false,
    skip: isCTAvailabilityActive,
  });

  const {
    data: category,
    loading: categoryLoading,
    error: categoryError,
  } = useGetCategoryByKey(PARENT_CFU_CATEGORY);

  const {
    loading: cfuLoading,
    data: product,
    error: searchProductError,
  } = useSearchProductByKey<CommercetoolsPackageQuery>(
    cfuSlug,
    useSearchBundleByKeyQuery,
    mapCTCFUtoKronosCFU,
    category?.id,
  );

  const loading = categoryLoading || cfuLoading || availabilityStateLoading;
  const error = (availabilityStateError ||
    searchProductError ||
    categoryError) as ApolloError;

  if (!isCTAvailabilityActive) {
    const availabilityStateCFU = availabilityState?.catalog?.packageBySlug?.availability;
    const commercetoolsCFU = product?.catalog?.packageBySlug;

    if (availabilityStateCFU && commercetoolsCFU)
      commercetoolsCFU.availability = availabilityStateCFU;
  }

  return {
    loading,
    data: product,
    error,
  };
};
