import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import {
  COMMERCETOOLS_AVAILABILITY_FF,
  COMMERCETOOLS_CFU_ACCESSORIES,
} from '../constants';

export const useIsCTAvailabilityActive = () => {
  const isToggleActive = useIsToggleActive();
  return (
    isToggleActive(COMMERCETOOLS_AVAILABILITY_FF) ||
    isToggleActive(COMMERCETOOLS_CFU_ACCESSORIES)
  );
};
